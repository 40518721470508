<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-col class="mb-0">
          <h1 class="display-1 font-weight-bold mt-3">
            Welcome to LiskRewards.com
          </h1>
          <p class="subheading font-weight-regular">
            Check how much Lisk Rewards you should get with your current voting and what payout you actually got.
          </p>
        </v-col>
      </v-row>

      <Introduction />
      <LiskRewards />
      <Projects class="mt-10" />
    </v-container>
  </div>
</template>

<script>
  import Introduction from '../components/Introduction';
  import LiskRewards from '../components/LiskRewards';
  import Projects from '../components/Projects';

  export default {
    name: 'Home',

    components: {
      Introduction,
      LiskRewards,
      Projects,
    },
  };
</script>
