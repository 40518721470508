<template>
<v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          src="./assets/logo_white.png"
          contain
          height="40px"
          width="40px"
          class="mr-2"
        ></v-img>
        <div class="text-h4 font-weight-bold">
          LiskRewards.com
        </div>
      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn
        href="about"
        target="_blank"
        text
      >
        <span class="mr-2">About</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view />

      <!-- <LiskRewards />
      <Projects class="mt-10" /> -->
    </v-main>
    <v-footer app>
      <v-container>
        <v-row class="d-flex justify-space-between">
          <div>
            made with 🧡 by cc001
          </div>
          <div class="text-right">
            <v-img
              src="./assets/discord.png"
              height="25px"
              width="25px"
              contain
              class="float-left mr-2"
            ></v-img>
            cc001#2828
          </div>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
  // import LiskRewards from './components/LiskRewards';
  // import Projects from './components/Projects';

  export default {
    name: 'App',

    components: {
      // HelloWorld,
      // LiskRewards,
      // Projects,
    },

    data: () => ({
      //
    }),
  };
</script>
