<template>
  <v-row>
    <v-col
      class="mb-5"
      cols="12"
    >
      <h2 class="headline font-weight-bold mb-3">
        Other great resources:
      </h2>
      <v-flex class="d-flex flex-wrap justify-space-around">


        <v-card
          class="ma-1 pa-1"
          v-for="project in projects"
          :key="project.name"
          width="350"
          :href="project.url"
          target="_blank"
        >
          <v-card-title>{{ project.name }}</v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle>Delegate <b>{{ project.from }}</b></v-card-subtitle>
          <v-card-text>{{ project.text }}</v-card-text>
        </v-card>
      </v-flex>

    </v-col>
  </v-row>
</template>

<script>
export default {
    name: 'Projects',

    data: () => ({
      projects: [{
          name: 'liskvoting.com',
          text: 'Optimize your voting rewards. Works perfectly in combination with LiskRewards.com',
          url: 'https://liskvoting.com',
          from: 'mrv'
        },
        {
          name: 'lisk.vote',
          text: 'Lisk Delegates List - The complete overview of Lisk validators for your Lisk staking',
          url: 'https://lisk.vote',
          from: 'carbonara'
        },
        {
          name: 'liskscan.com',
          text: 'A great Lisk Blockchain Explorer',
          url: 'https://liskscan.com',
          from: 'moosty'
        },
        {
          name: 'lisk.observer',
          text: 'Another great Lisk Blockchain Explorer',
          url: 'https://lisk.observer',
          from: 'carbonara'
        },
        {
          name: 'punkrock.github.io',
          text: 'Useful guides to setup and maintain lisk nodes',
          url: 'https://punkrock.github.io/lisk.html',
          from: 'punkrock'
        },
        {
          name: 'github.com/Gr33nDrag0n69',
          text: 'Great scripts and tools to manage and monitor lisk nodes',
          url: 'https://github.com/Gr33nDrag0n69/LiskCore3Tools',
          from: 'gr33ndrag0n'
        },
        {
          name: 'LiskApps',
          text: 'Your go-to platform for everything related to dApps built on Lisk technology',
          url: 'https://liskapps.com/',
          from: 'lemii'
        },
        {
          name: 'github.com/Lemii/guides',
          text: 'Nice guides about securing and managing lisk nodes',
          url: 'https://github.com/Lemii/guides',
          from: 'lemii'
        },
        {
          name: 'Lisk.support',
          text: 'The go-to community website for everything you need to know about Lisk',
          url: 'https://www.lisk.support/',
          from: 'tonyt908'
        },
      ]
    }),
    mounted() {
      this.shuffleProjects()
    },

    methods: {
      shuffleProjects() {
        this.projects.sort(() => (Math.random() > .5) ? 1 : -1)
      }
    }
  }
</script>
