<template>
<v-container>
    <!-- <p>cc001: lskq46zcjg9dz2mjc4akgsaozyhayw4pfkjaw4zkg</p>
    <p>Server Maintenance: lsk685ttear4n4tmahu2k34odyyu6xxtyqw5cv2pa</p>
    <p>Donations Projects: lsk3y9affbtc9f8qy74d4eyoo9wsnndteo9psu6a6</p>
    <p>Donations Users: lskxvkn7q6zv6vqf6e7vrbmrfu82f4uktm9wfnyex</p>
    <p>Donations: lskqz7pfatjzzj3qd5ssywrnsajw7pzjmnsze7jxn</p>
    <p>Example 1: lskjgmnc3e5yze57mho48dbqymb5qvk6d3nz33qyb</p>
    <p>Example 2: lskmg3sdmjp4smz6x9k2cuyuwags5ehgtexe4w2ds</p>
    <p>Example 3: lskxsvz4n9ghhs8ne4terermr4m8ucohesef7ucoq</p>
    <p no votes: lskg6k547scdgod56qe7byr7nemdwtevvxt28c7eh  -->
    <v-row>
      <v-col>
        <h2 class="headline font-weight-bold mb-3">
          Saved Accounts
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <div style="width: 300px">To access your accounts quickly, enter the address below, add an "Account Name", and click "Save Account". The saved accounts will show up here and you can simply click on them instead of having to enter the
              address manually every time.</div>
          </v-tooltip>
        </h2>
      </v-col>
    </v-row>

    <v-container
      v-if="savedAccounts.length > 0"
      class="d-flex flex-wrap justify-start"
    >
      <v-card
        v-for="savedAccount in savedAccounts"
        :key="savedAccount.name"
        :href="savedAccount.link"
        width="200"
        class="ma-1"
      >
        <v-card-title>
          {{savedAccount.name}}
        </v-card-title>
        <v-card-text class="text-caption">
          <!-- <a :href="savedAccount.link">{{savedAccount.address}}</a> -->
          {{savedAccount.address}}
        </v-card-text>
        <v-card-actions>
          <v-icon
            @click="removeSavedAccount(savedAccount.name)"
            v-on:click.prevent
          >mdi-delete-forever</v-icon>
        </v-card-actions>
      </v-card>
    </v-container>
    <div
      v-else
      class="text-caption ml-2"
    >
      No accounts saved.
    </div>

    <v-row class="mt-5">
      <v-col>
        <h2 class="headline font-weight-bold mb-3">
          Show Account
        </h2>
      </v-col>
    </v-row>

    <v-row class="mt-5 mb-3">
      <v-col
        cols="12"
        sm="12"
        md="5"
        lg="5"
        xl="5"
        class="pa-0 pl-3"
      >
        <v-text-field
          dense
          class="mr-3"
          v-model="address"
          label="Lisk Address"
          @input="refreshAddress"
          outlined
          clearable
          @click:clear="clearAddress"
          append-icon="mdi-magnify"
          :rules="[rules.address]"
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="5"
        lg="5"
        xl="5"
        class="pa-0 pl-3"
      >
        <v-text-field
          dense
          :disabled="!address || !isValidAccount(address)"
          class="mr-3"
          v-model="saveAccountName"
          label="Account Name"
          outlined
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
        xl="2"
        class="pa-0 pl-3"
      >
        <v-btn
          :disabled="!address || !isValidAccount(address) || !saveAccountName || saveAccountNameExists(saveAccountName)"
          @click="saveAccount"
        >Save Account
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-if="directLink"
      class="mt-0"
    >
      <v-col>
        <p class="text-caption ma-0">Direct Link: <router-link v-bind:to="directLink">{{directLinkText}}</router-link>
        </p>
      </v-col>
    </v-row>


    <v-row v-if="this.notVoted">
      <v-col cols="12">
        <h2 class="headline font-weight-bold mb-3">
          Account {{ address }} has no votes
        </h2>
      </v-col>
    </v-row>

    <v-row v-if="votes.length > 0 && votedForCc001">
      <v-col cols="12">
        <v-alert type="success">
          Thanks for voting for me, it is very much appreciated!<br>
          Your support helps me to continue my work on <a href="https://liskrewards.com">LiskRewards.com</a> and <a href="https://bdonor.io">b🧡donor</a>
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="votes.length > 0 && !votedForCc001">
      <v-col cols="12">
        <v-alert type="warning">
          Please consider voting for me, delegate <b>cc001</b><br>It helps me to continue my work on <a href="https://liskrewards.com">LiskRewards.com</a> and <a href="https://bdonor.io">b🧡donor</a>. Thanks for your support
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="this.notVoted">
      <v-col cols="12">
        <v-alert type="info">
          Please consider voting for me, delegate <b>cc001</b><br>It helps me to continue my work on <a href="https://liskrewards.com">LiskRewards.com</a> and <a href="https://bdonor.io">b🧡donor</a>. Thanks for your support
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers_votes"
          :hide-default-header="isMobile"
          :items="votes"
          dense
          disable-pagination
          hide-default-footer
          item-key="delegate"
          :class="{mobile: isMobile}"
          :sort-by="votesSort"
          :sort-desc="votesSortDesc"
        >
          <template v-slot:item="{ item }">
            <tr
              v-if="!isMobile"
              :class="itemRowBackground(item)"
            >
              <td class="text-left"><a
                  :href="item.delegatelink"
                  target="_blank"
                >{{item.delegate}}</a>
                <v-tooltip
                  top
                  v-if="item.pomed"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-message-alert
                    </v-icon>
                  </template>
                  <span>Delegate is currently punished for misbehaving and will most likely not pay any rewards</span>
                </v-tooltip>
              </td>
              <td class="text-right">{{item.weight}} LSK</td>
              <td class="text-right">{{item.sharingpercentage.toFixed(0)}}%</td>
              <!-- <td
                class="text-right"
              >
                <v-chip
                  :color="item.honestycolor"
                  label
                  small
                  class="chipsize2 my-1 text-right px-auto"
                >{{ item.honesty.toFixed(0) }}%</v-chip>
              </td> -->
              <td
                class="text-right"
                v-if="item.sharingpercentage > 0 && item.selfvote_excluded"
              >
                <v-icon color="green">mdi-check-outline</v-icon>
              </td>
              <td
                class="text-right"
                v-else-if="item.sharingpercentage > 0 && !item.selfvote_excluded"
              >
                <v-icon color="orange">mdi-close-box-outline</v-icon>
              </td>
              <td
                class="text-right"
                v-else
              >-</td>
              <!-- <td class="text-right">?</td> -->
              <td class="text-right">{{item.counted_pool_weight}}</td>
              <td class="text-right">{{item.pool_rank}}</td>
              <!-- <td class="text-right">{{item.reward_block.toFixed(4)}} LSK</td> -->
              <td class="text-right">{{item.reward_month_lsk.toFixed(2)}} LSK</td>
              <td class="text-right">{{item.reward_month_usd.toFixed(2)}} USD</td>
              <td class="text-right">
                <v-chip
                  :color="item.honestycolor"
                  label
                  small
                  class="chipsize2 my-1 text-right px-auto"
                >{{ item.honesty.toFixed(0) }}%</v-chip>
              </td>
              <td class="text-right">{{item.apr.toFixed(2)}}%</td>
              <td class="text-right">
                {{item.apr_real.toFixed(2)}}%
              </td>


            </tr>
            <tr v-else>
              <td class="text-left">
                <ul class="flex-content">
                  <li
                    class="flex-item"
                    data-label="Delegate"
                  ><a
                      :href="item.delegatelink"
                      target="_blank"
                    >{{item.delegate}}</a>
                    <v-tooltip
                      top
                      v-if="item.pomed"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-2"
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-message-alert
                        </v-icon>
                      </template>
                      <span>Delegate is currently punished for misbehaving and will most likely not pay any rewards</span>
                    </v-tooltip>
                  </li>
                  <li
                    class="flex-item"
                    data-label="Voting Amount"
                  >{{ item.weight }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Promised Sharing"
                  >{{ item.sharingpercentage.toFixed(2) }}%</li>
                  <!-- <li
                    class="flex-item"
                    data-label="Honesty"
                  >
                    <v-chip
                      :color="item.honestycolor"
                      label
                      small
                      class="chipsize2 mt-0 mb-1 text-right px-auto"
                    >{{ item.honesty.toFixed(0) }}%</v-chip>
                  </li> -->

                  <!-- <li
                    class="flex-item"
                    data-label="Selfvote excluded"
                  >?</li> -->
                  <li
                    v-if="item.sharingpercentage > 0 && item.selfvote_excluded"
                    class="flex-item"
                    data-label="Selfvote excluded"
                  >
                    <v-icon color="green">mdi-check-outline</v-icon>
                  </li>
                  <li
                    v-else-if="item.sharingpercentage > 0 && !item.selfvote_excluded"
                    class="flex-item"
                    data-label="Selfvote excluded"
                  >
                    <v-icon color="orange">mdi-close-box-outline</v-icon>
                  </li>
                  <li
                    v-else
                    class="flex-item"
                    data-label="Selfvote excluded"
                  >-</li>
                  <li
                    class="flex-item"
                    data-label="Delegate Weight"
                  >{{ item.counted_pool_weight }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Delegate Rank"
                  >{{ item.pool_rank }}</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Block"
                  >{{ item.reward_block.toFixed(4) }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Month"
                  >{{ item.reward_month_lsk.toFixed(2) }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Month"
                  >{{ item.reward_month_usd.toFixed(2) }} USD</li>
                  <li
                    class="flex-item"
                    data-label="Honesty"
                  >
                    <v-chip
                      :color="item.honestycolor"
                      label
                      small
                      class="chipsize2 text-right px-auto"
                    >{{ item.honesty.toFixed(0) }}%</v-chip>
                  </li>
                  <li
                    class="flex-item"
                    data-label="Promised APR"
                  >{{ item.apr.toFixed(2) }}%</li>
                  <li
                    class="flex-item"
                    data-label="Real APR"
                  >{{ item.apr_real.toFixed(2) }}%</li>
                </ul>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              More info about {{ item.delegate }}
            </td>
          </template>
          <template slot="body.append">
            <tr
              class="primary font-weight-bold"
              v-if="votes.length>0 && !isMobile"
            >
              <td class="text-left">Total</td>
              <td class="text-right">{{ sumField(votes, 'weight') }} LSK</td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <!-- <td class="text-right"></td> -->
              <td class="text-right"></td>
              <!-- <td class="text-right">{{ sumField(votes, 'reward_block').toFixed(4) }} LSK</td> -->
              <td class="text-right">{{ sumField(votes, 'reward_month_lsk').toFixed(2) }} LSK</td>
              <td class="text-right">{{ sumField(votes, 'reward_month_usd').toFixed(2) }} USD</td>
              <td class="text-right"></td>
              <td class="text-right">{{ (sumField(votes, 'reward_month_lsk')*12/sumField(votes, 'weight')*100).toFixed(2) }}%</td>
              <td class="text-right">{{ (sumField(votes, 'reward_month_lsk_real')*12/sumField(votes, 'weight')*100).toFixed(2) }}%</td>
            </tr>
            <tr
              class="primary font-weight-bold"
              v-else-if="votes.length>0 && isMobile"
            >
              <td class="text-left">
                <ul class="flex-content">
                  <li
                    class="flex-item"
                    data-label="TOTAL"
                  ></li>
                  <li
                    class="flex-item"
                    data-label="Voting Amount"
                  >{{ sumField(votes, 'weight') }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Block"
                  >{{ sumField(votes, 'reward_block').toFixed(4) }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Month"
                  >{{ sumField(votes, 'reward_month_lsk').toFixed(2) }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Month"
                  >{{ sumField(votes, 'reward_month_usd').toFixed(2) }} USD</li>
                  <li
                    class="flex-item"
                    data-label="APR"
                  >{{ (sumField(votes, 'reward_month_lsk')*12/sumField(votes, 'weight')*100).toFixed(2) }}%</li>
                </ul>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2 class="headline font-weight-bold mb-3">
          Not Voted
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="4"
        sm="4"
        lg="4"
        xl="4"
      >
        <v-text-field
          v-model="voteWeight"
          label="Vote Weight"
          @input="getNonVotes"
          outlined
          clearable
          suffix="LSK"
          :rules="[rules.required, rules.numeric]"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers_nonvotes"
          :hide-default-header="isMobile"
          :items="nonVotes"
          dense
          disable-pagination
          hide-default-footer
          item-key="delegate"
          :height="nonVotes.length > 0 ? 400 : 80"
          :class="{mobile: isMobile}"
          :sort-by="nonVotesSort"
          :sort-desc="nonVotesSortDesc"
        >
          <template v-slot:item="{ item }">
            <tr
              v-if="!isMobile"
              :class="itemRowBackground(item)"
            >
              <td class="text-left"><a
                  :href="item.delegatelink"
                  target="_blank"
                >{{item.delegate}}</a>
                <v-tooltip
                  top
                  v-if="item.pomed"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-message-alert
                    </v-icon>
                  </template>
                  <span>Delegate is currently punished for misbehaving and will most likely not pay any rewards</span>
                </v-tooltip>
              </td>
              <td class="text-right">{{item.sharingpercentage.toFixed(0)}}%</td>
              <td
                class="text-right"
                v-if="item.sharingpercentage > 0 && item.selfvote_excluded"
              >
                <v-icon color="green">mdi-check-outline</v-icon>
              </td>
              <td
                class="text-right"
                v-else-if="item.sharingpercentage > 0 && !item.selfvote_excluded"
              >
                <v-icon color="orange">mdi-close-box-outline</v-icon>
              </td>
              <td
                class="text-right"
                v-else
              >-</td>
              <td class="text-right">{{item.counted_pool_weight}}</td>
              <td class="text-right">{{item.pool_rank}}</td>
              <td class="text-right">{{item.reward_month_lsk.toFixed(2)}} LSK</td>
              <td class="text-right">{{item.reward_month_usd.toFixed(2)}} USD</td>
              <td class="text-right">
                <v-chip
                  :color="item.honestycolor"
                  label
                  small
                  class="chipsize2 my-1 text-right px-auto"
                >{{ item.honesty.toFixed(0) }}%</v-chip>
              </td>
              <td class="text-right">{{item.apr.toFixed(2)}}%</td>
              <td class="text-right">
                {{item.apr_real.toFixed(2)}}%
              </td>

            </tr>
            <tr v-else>
              <td class="text-left">
                <ul class="flex-content">
                  <li
                    class="flex-item"
                    data-label="Delegate"
                  ><a
                      :href="item.delegatelink"
                      target="_blank"
                    >{{item.delegate}}</a>
                    <v-tooltip
                      top
                      v-if="item.pomed"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-2"
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-message-alert
                        </v-icon>
                      </template>
                      <span>Delegate is currently punished for misbehaving and will most likely not pay any rewards</span>
                    </v-tooltip>
                  </li>
                  <li
                    class="flex-item"
                    data-label="Promised Sharing"
                  >{{ item.sharingpercentage.toFixed(0) }}%</li>
                  <li
                    v-if="item.sharingpercentage > 0 && item.selfvote_excluded"
                    class="flex-item"
                    data-label="Selfvote excluded"
                  >
                    <v-icon color="green">mdi-check-outline</v-icon>
                  </li>
                  <li
                    v-else-if="item.sharingpercentage > 0 && !item.selfvote_excluded"
                    class="flex-item"
                    data-label="Selfvote excluded"
                  >
                    <v-icon color="orange">mdi-close-box-outline</v-icon>
                  </li>
                  <li
                    v-else
                    class="flex-item"
                    data-label="Selfvote excluded"
                  >-</li>
                  <li
                    class="flex-item"
                    data-label="Delegate Weight"
                  >{{ item.counted_pool_weight }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Delegate Rank"
                  >{{ item.pool_rank }}</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Block"
                  >{{ item.reward_block.toFixed(4) }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Month"
                  >{{ item.reward_month_lsk.toFixed(2) }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Reward/Month"
                  >{{ item.reward_month_usd.toFixed(2) }} USD</li>
                  <li
                    class="flex-item"
                    data-label="Honesty"
                  >
                    <v-chip
                      :color="item.honestycolor"
                      label
                      small
                      class="chipsize2 text-right px-auto"
                    >{{ item.honesty.toFixed(0) }}%</v-chip>
                  </li>
                  <li
                    class="flex-item"
                    data-label="Promised APR"
                  >{{ item.apr.toFixed(2) }}%</li>
                  <li
                    class="flex-item"
                    data-label="Real APR"
                  >{{ item.apr_real.toFixed(2) }}%</li>
                </ul>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2 class="headline font-weight-bold mb-3">
          Payouts
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers_payouts"
          :hide-default-header="isMobile"
          :items="payoutsArray"
          disable-pagination
          hide-default-footer
          item-key="delegate"
          show-expand
          dense
          :expanded.sync="expanded"
          :single-expand="singleExpand"
          :class="{mobile: isMobile}"
        >
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr
              v-if="!isMobile"
              :class="itemRowBackground(item)"
            >
              <td class="text-left"><a
                  :href="item.delegatelink"
                  target="_blank"
                >{{item.delegate}}</a>
                <v-tooltip
                  top
                  v-if="item.pomed"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-message-alert
                    </v-icon>
                  </template>
                  <span>Delegate is currently punished for misbehaving and will most likely not pay any rewards</span>
                </v-tooltip>
              </td>
              <td class="text-right">{{item.number_payouts}}</td>
              <td class="text-right">{{item.total_payouts_lsk.toFixed(2)}} LSK</td>
              <td class="text-right">{{item.total_payouts_usd.toFixed(2)}} USD</td>
              <!-- <td><v-btn @click="expand(!isExpanded)">Expand</v-btn></td> -->
              <td>
                <v-icon @click="expand(!isExpanded)">mdi-magnify</v-icon>
              </td>
            </tr>
            <tr v-else>
              <td class="text-left">
                <ul class="flex-content">
                  <li
                    class="flex-item"
                    data-label="Sender"
                  ><a
                      :href="item.delegatelink"
                      target="_blank"
                    >{{item.delegate}}</a></li>
                  <li
                    class="flex-item"
                    data-label="Number of payouts"
                  >{{ item.number_payouts }}</li>
                  <li
                    class="flex-item"
                    data-label="Total Payout (LSK)"
                  >{{ item.total_payouts_lsk.toFixed(2) }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Total Payout (USD)"
                  >{{ item.total_payouts_usd.toFixed(2) }} USD</li>
                  <li
                    class="flex-item"
                    data-label="Details"
                  >
                    <v-icon @click="expand(!isExpanded)">mdi-magnify</v-icon>
                  </li>
                </ul>
              </td>
            </tr>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="!isMobile"
          >
            <tr
              v-for="payout in item.payouts"
              :key="payout.timestamp"
              class=""
            >
              <td class="pl-10 text-caption payout">{{ payout.datetimestring }}</td>
              <td class="text-right text-caption payout display-block"><span class="float-left pt-1">{{ payout.transactionid }}</span><a :href="liskoberserverLink(payout.transactionid)">
                  <v-img
                    height="24px"
                    width="24px"
                    contain
                    :src="require('../assets/favicon_liskobserver.png')"
                    class="float-right ml-2"
                  ></v-img>
                </a><a :href="liskscanLink(payout.transactionid)">
                  <v-img
                    height="24px"
                    width="24px"
                    contain
                    :src="require('../assets/Liskscan_logo.svg')"
                    class="float-right"
                  ></v-img>
                </a></td>
              <td class="text-right text-caption payout">{{ payout.amount_lsk.toFixed(2) }} LSK</td>
              <td class="text-right text-caption payout">{{ payout.amount_usd.toFixed(2) }} USD</td>
            </tr>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-else
          >
            <tr
              v-for="payout in item.payouts"
              :key="payout.timestamp"
              class=""
            >
              <td class="text-left">
                <ul class="flex-content">
                  <li
                    class="flex-item text-caption"
                    data-label="Date/Time"
                  >{{ payout.datetimestring }}</li>
                  <li
                    class="flex-item text-caption"
                    data-label="TxId"
                  ><span class="float-left pt-1"></span><a :href="liskoberserverLink(payout.transactionid)">
                      <v-img
                        height="24px"
                        width="24px"
                        contain
                        :src="require('../assets/favicon_liskobserver.png')"
                        class="float-right ml-2"
                      ></v-img>
                    </a><a :href="liskscanLink(payout.transactionid)">
                      <v-img
                        height="24px"
                        width="24px"
                        contain
                        :src="require('../assets/Liskscan_logo.svg')"
                        class="float-right"
                      ></v-img>
                    </a></li>
                  <li
                    class="flex-item text-caption"
                    data-label="Amount (LSK)"
                  >{{ payout.amount_lsk.toFixed(2) }} LSK</li>
                  <li
                    class="flex-item text-caption"
                    data-label="Amount (USD)"
                  >{{ payout.amount_usd.toFixed(2) }} USD</li>
                </ul>
              </td>

            </tr>
          </template>

          <template slot="body.append">
            <tr
              class="primary font-weight-bold"
              v-if="votes.length>0 && !isMobile"
            >
              <td class="text-left">Total</td>
              <td class="text-right">{{ sumField(payoutsArray, 'number_payouts') }}</td>
              <td class="text-right">{{ sumField(payoutsArray, 'total_payouts_lsk').toFixed(2) }} LSK</td>
              <td class="text-right">{{ sumField(payoutsArray, 'total_payouts_usd').toFixed(2) }} USD</td>
              <td></td>
            </tr>
            <tr
              class="primary font-weight-bold"
              v-else-if="votes.length>0 && isMobile"
            >
              <td class="text-left">
                <ul class="flex-content">
                  <li
                    class="flex-item"
                    data-label="TOTAL"
                  ></li>
                  <li
                    class="flex-item"
                    data-label="Number of Payouts"
                  >{{ sumField(payoutsArray, 'number_payouts') }}</li>
                  <li
                    class="flex-item"
                    data-label="Total Payouts (LSK)"
                  >{{ sumField(payoutsArray, 'total_payouts_lsk').toFixed(2) }} LSK</li>
                  <li
                    class="flex-item"
                    data-label="Total Payouts (USD)"
                  >{{ sumField(payoutsArray, 'total_payouts_usd').toFixed(2) }} USD</li>
                </ul>
              </td>
            </tr>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2 class="headline font-weight-bold mb-3">
          Delegate Honesty
        </h2>
        <h4>
          Calculations generated by delegate lemii's <a href="https://lemii.dev/validator">validator tool</a>. (last update from {{ lastUpdate_datestring }})
        </h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="filter_delegatename"
          append-icon="mdi-magnify"
          label="Filter Delegatename"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="delegatehonesty_headers"
          :hide-default-header="isMobile"
          :items="delegatehonesty"
          :search="filter_delegatename"
          dense
          disable-pagination
          hide-default-footer
          item-key="username"
          show-expand
          @item-expanded="loadDetails"
          :expanded.sync="expanded_honesty"
          :single-expand="singleExpand_honesty"
          :class="{mobile: isMobile}"
          :height="delegatehonesty.length > 0 ? 600 : 80"
        >
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr v-if="!isMobile">
              <td class="text-right">{{item.rank}}</td>
              <td class="text-left"><a
                  :href="item.delegatelink"
                  target="_blank"
                >{{item.username}}</a></td>
              <!-- <td class="text-right">{{item.weight}} LSK</td>
              <td class="text-right">{{item.sharingpercentage}}%</td> -->
              <td class="text-right">
                <v-chip
                  v-if="item.lastPayout>=0"
                  :color="item.lastPayoutColor"
                  label
                  small
                  class="chipsize2 my-1 text-right px-auto"
                >{{ item.lastPayout }}</v-chip>
              </td>
              <td class="text-right">{{item.recordedSessions}}</td>
              <td class="text-right">{{item.promised.toFixed(0)}}%</td>
              <td class="text-right">{{item.shared}}%</td>
              <td class="text-right">
                <v-chip
                  :color="item.honestycolor"
                  label
                  small
                  class="chipsize2 my-1 text-right px-auto"
                >{{ item.honesty.toFixed(0) }}%</v-chip>
              </td>
              <td>
                <v-icon
                  v-if="item.recordedSessions > 0 && !isExpanded"
                  @click="expand(!isExpanded)"
                >mdi-chevron-down</v-icon>
                <v-icon
                  v-if="item.recordedSessions > 0 && isExpanded"
                  @click="expand(!isExpanded)"
                >mdi-chevron-up</v-icon>
              </td>
            </tr>
            <tr v-else>
              <td class="text-left">
                <ul class="flex-content mb-1">
                  <li
                    class="flex-item"
                    data-label="Rank"
                  >{{ item.rank }}</li>
                  <li
                    class="flex-item"
                    data-label="Delegate"
                  ><a
                      :href="item.delegatelink"
                      target="_blank"
                    >{{item.username}}</a></li>
                  <li
                    v-if="item.lastPayout>=0"
                    class="flex-item"
                    data-label="Days since last payout"
                  >
                    <v-chip
                      :color="item.lastPayoutColor"
                      label
                      small
                      class="chipsize2 text-right px-auto"
                    >{{ item.lastPayout }}</v-chip>
                  </li>
                  <li
                    class="flex-item"
                    data-label="Number Payouts"
                  >{{ item.recordedSessions }}</li>
                  <li
                    class="flex-item"
                    data-label="Promised"
                  >{{ item.promised }}%</li>
                  <li
                    class="flex-item"
                    data-label="Average Shared"
                  >{{ item.shared }}%</li>
                  <li
                    class="flex-item"
                    data-label="Honesty"
                  >
                    <v-chip
                      :color="item.honestycolor"
                      label
                      small
                      class="chipsize2 text-right px-auto"
                    >{{ item.honesty.toFixed(0) }}%</v-chip>
                  </li>
                  <li
                    v-if="item.recordedSessions > 0"
                    class="flex-item"
                    data-label="Details"
                  >
                    <v-icon
                      v-if="!isExpanded"
                      @click="expand(!isExpanded)"
                    >mdi-chevron-down</v-icon>
                    <v-icon
                      v-if="isExpanded"
                      @click="expand(!isExpanded)"
                    >mdi-chevron-up</v-icon>
                  </li>
                </ul>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <tr v-if="!isMobile">
              <td
                colspan="6"
                class="pa-0"
              >
                <v-data-table
                  :headers="payoutsession_headers"
                  :hide-default-header="isMobile"
                  :items="item.payoutSessions"
                  dense
                  disable-pagination
                  hide-default-footer
                  item-key="startTimestamp"
                  :class="{mobile: isMobile}"
                >
                  <template v-slot:item="{ item }">
            <tr>
              <td class="text-left pl-10 text-caption payout">{{ item.date }}</td>
              <td class="text-right pl-10 text-caption payout">{{ item.startHeight }}</td>
              <td class="text-right text-caption payout">{{ item.timePeriod }}</td>
              <td class="text-right text-caption payout">{{ item.totalForged.toFixed(2) }} LSK</td>
              <td class="text-right text-caption payout">{{ item.totalPaid.toFixed(2) }} LSK</td>
              <td class="text-right text-caption payout">{{ item.calculatedShare.toFixed(2) }}%</td>
              <td class="text-right text-caption payout">
                <v-chip
                  :color="item.honestycolor"
                  label
                  small
                  class="chipsize2 my-1 text-right px-auto"
                >{{ item.honesty.toFixed(0) }}%</v-chip>
              </td>
              <!-- <td class="text-right text-caption payout">{{ item.honesty.toFixed(2) }}%</td> -->
            </tr>
          </template>
        </v-data-table>
        </td>
        </tr>
        <tr
          v-else
          v-for="payoutSession in item.payoutSessions"
          :key="payoutSession.date"
          class=""
        >
          <td class="text-left">
            <ul class="flex-content mb-1">
              <li
                class="flex-item text-caption"
                data-label="Payout Date"
              >{{ payoutSession.date }}</li>
              <li
                class="flex-item text-caption"
                data-label="Block"
              >{{ payoutSession.startHeight }}</li>
              <li
                class="flex-item text-caption"
                data-label="Days since last payout"
              >{{ payoutSession.timePeriod }}</li>
              <li
                class="flex-item text-caption"
                data-label="Total Forged"
              >{{ payoutSession.totalForged.toFixed(2) }} LSK</li>
              <li
                class="flex-item text-caption"
                data-label="Total Payed"
              >{{ payoutSession.totalPaid.toFixed(2) }} LSK</li>
              <li
                class="flex-item text-caption"
                data-label="Shared"
              >{{ payoutSession.calculatedShare.toFixed(2) }}%</li>
              <li
                class="flex-item text-caption"
                data-label="Honesty"
              >
                <v-chip
                  :color="payoutSession.honestycolor"
                  label
                  small
                  class="chipsize2 text-right px-auto"
                >{{ payoutSession.honesty.toFixed(0) }}%</v-chip>
              </li>
            </ul>
          </td>

        </tr>
        </template>
        </v-data-table>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12">
        <h2 class="headline font-weight-bold mb-3">
          Voting Transactions Timeline
        </h2>
      </v-col>
    </v-row>

    <v-timeline :dense="isMobile">
      <v-timeline-item
        v-for="votingTransaction in votingTransactions"
        :key="votingTransaction.id"
        icon="mdi-vote"
      >
        <v-card>
          <v-card-title>
            {{votingTransaction.datetimestring}} - {{votingTransaction.votes.length}} Votes
          </v-card-title>
          <v-card-subtitle>
            <a
              :href="votingTransaction.transactionlink"
              target="_blank"
              class="text-caption"
            >{{shortenedString(votingTransaction.id, 10, 4)}}</a>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <div
                v-for="vote in votingTransaction.votes"
                :key="vote.username"
                class="d-flex justify-space-between"
              >
                <div class="pt-1 width150"><strong>{{ vote.username }}</strong></div>
                <v-chip
                  :color="vote.amount > 0 ? 'green' : 'orange'"
                  label
                  small
                  class="chipsize mt-1 text-right px-auto"
                >{{ vote.amount }}</v-chip>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>

  </v-container>
</template>

<script>
import axios from "axios";

  import {
    shortenedString,
    isValidAccount
  } from "../utils/helpers.js"

  export default {
    name: 'LiskRewards',

    data: () => ({
      SATOSHIS: 100000000,
      BLOCKS_PER_MONTH: 2500,
      lskPrice: 0.0,
      isMobile: false,
      address: "",
      expanded: [],
      isExpanded: false,
      singleExpand: false,
      voteWeight: 1000,
      expanded_honesty: [],
      singleExpand_honesty: false,
      filter_delegatename: '',
      lastUpdate_datestring: '',
      rules: {
        required: v => !!v || 'Required',
        numeric: v => Number.isInteger(parseInt(v)) || 'Enter a number',
        address: v => !v || /^lsk[a-z0-9]{38}$/.test(v) || 'Invalid Lisk32 Address'
      },
      headers_votes: [{
          text: "Delegate",
          align: "left",
          sortable: true,
          value: "delegate"
        },
        {
          text: "Voting Amount",
          align: "right",
          sortable: true,
          value: "weight"
        },
        {
          text: "Sharing",
          align: "right",
          sortable: true,
          value: "sharingpercentage"
        },
        {
          text: "Selfvote excl.",
          align: "right",
          sortable: true,
          value: "selfvote_excluded"
        },
        {
          text: "Weight",
          align: "right",
          sortable: true,
          value: "counted_pool_weight"
        },
        {
          text: "Rank",
          align: "right",
          sortable: true,
          value: "pool_rank"
        },
        {
          text: "Reward/Month",
          align: "right",
          sortable: true,
          value: "reward_month_lsk"
        },
        {
          text: "Reward/Month",
          align: "right",
          sortable: true,
          value: "reward_month_usd"
        },
        {
          text: "Honesty",
          align: "right",
          sortable: true,
          value: "honesty"
        },
        {
          text: "Promised APR",
          align: "right",
          sortable: true,
          value: "apr"
        },
        {
          text: "Real APR",
          align: "right",
          sortable: true,
          value: "apr_real"
        },

      ],
      headers_nonvotes: [{
          text: "Delegate",
          align: "left",
          sortable: true,
          value: "delegate"
        },
        {
          text: "Promised Sharing",
          align: "right",
          sortable: true,
          value: "sharingpercentage"
        },
        {
          text: "Selfvote excl.",
          align: "right",
          sortable: true,
          value: "selfvote_excluded"
        },
        {
          text: "Weight",
          align: "right",
          sortable: true,
          value: "counted_pool_weight"
        },
        {
          text: "Rank",
          align: "right",
          sortable: true,
          value: "pool_rank"
        },
        {
          text: "Reward/Month",
          align: "right",
          sortable: true,
          value: "reward_month_lsk"
        },
        {
          text: "Reward/Month",
          align: "right",
          sortable: true,
          value: "reward_month_usd"
        },
        {
          text: "Honesty",
          align: "right",
          sortable: true,
          value: "honesty"
        },
        {
          text: "Promised APR",
          align: "right",
          sortable: true,
          value: "apr"
        },
        {
          text: "Real APR",
          align: "right",
          sortable: true,
          value: "apr_real"
        },

      ],
      delegatehonesty_headers: [{
          text: "Rank",
          align: "right",
          sortable: true,
          value: "rank"
        },
        {
          text: "Delegate",
          align: "left",
          sortable: true,
          value: "username"
        },
        {
          text: "Days since last payout",
          align: "right",
          sortable: true,
          value: "lastPayout"
        },
        {
          text: "Number Payouts",
          align: "right",
          sortable: true,
          value: "recordedSessions"
        },
        {
          text: "Promised",
          align: "right",
          sortable: true,
          value: "promised"
        },
        {
          text: "Average Shared",
          align: "right",
          sortable: true,
          value: "shared"
        },
        {
          text: "Honesty",
          align: "right",
          sortable: true,
          value: "honesty"
        },
        {
          text: '',
          value: 'data-table-expand'
        },
      ],
      payoutsession_headers: [{
          text: "Payout Date",
          align: "left",
          sortable: true,
          value: "date"
        },
        {
          text: "Block",
          align: "right",
          sortable: true,
          value: "startHeight"
        },
        {
          text: "Days since last payout",
          align: "right",
          sortable: true,
          value: "timePeriod"
        },
        {
          text: "Total Forged",
          align: "right",
          sortable: true,
          value: "totalForged"
        },
        {
          text: "Total Payed",
          align: "right",
          sortable: true,
          value: "totalPaid"
        },
        {
          text: "Shared",
          align: "right",
          sortable: true,
          value: "calculatedShare"
        },
        {
          text: "Honesty",
          align: "right",
          sortable: true,
          value: "honesty"
        },
      ],
      headers_payouts: [{
          text: "Sender",
          align: "left",
          sortable: true,
          value: "delegate"
        },
        {
          text: "Number of payouts",
          align: "right",
          sortable: true,
          value: "number_payouts"
        },
        {
          text: "Total Payout (LSK)",
          align: "right",
          sortable: true,
          value: "total_payouts_lsk"
        },
        {
          text: "Total Payout (USD)",
          align: "right",
          sortable: true,
          value: "total_payouts_usd"
        },
        {
          text: '',
          value: 'data-table-expand'
        },
      ],
      headers_votingTransactions: [{
          text: "Date/Time",
          align: "left",
          sortable: true,
          value: "datetimestring"
        },
        {
          text: "Transaction",
          align: "left",
          sortable: false,
          value: "id"
        },
        {
          text: "Votes",
          align: "left",
          sortable: false,
        },
      ],
      votes: [],
      nonVotes: [],
      pool_sharings: new Map([

        ['moosty', {
          'sharing': 0.2,
          'selfvote_excluded': true
        }],
        ['eastwind_ja', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['liskearn', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['korben3', {
          'sharing': 0.2,
          'selfvote_excluded': true
        }],
        ['samuray', {
          'sharing': 0.5,
          'selfvote_excluded': false
        }],
        ['ondin', {
          'sharing': 0.7,
          'selfvote_excluded': true
        }],
        ['private_pool', {
          'sharing': 0.7,
          'selfvote_excluded': false
        }],
        ['jeevanio_pool', {
          'sharing': 0.75,
          'selfvote_excluded': true
        }],
        ['liskmagazine', {
          'sharing': 0.6,
          'selfvote_excluded': false
        }],
        ['loteria', {
          'sharing': 0.6,
          'selfvote_excluded': true
        }],
        ['jump_pool', {
          'sharing': 0.7,
          'selfvote_excluded': true
        }],
        ['helpinghand', {
          'sharing': 0.6,
          'selfvote_excluded': true
        }],
        ['lemii', {
          'sharing': 0.2,
          'selfvote_excluded': true
        }],
        ['benevale', {
          'sharing': 0.7,
          'selfvote_excluded': true
        }],
        ['liskroad', {
          'sharing': 0.7,
          'selfvote_excluded': true
        }],
        ['jesusthehun', {
          'sharing': 0.25,
          'selfvote_excluded': true
        }],
        ['minions', {
          'sharing': 0.2,
          'selfvote_excluded': true
        }],
        ['fridge', {
          'sharing': 0.7,
          'selfvote_excluded': false
        }],
        ['gr33ndrag0n', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['carbonara', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['vipertkd', {
          'sharing': 0.5,
          'selfvote_excluded': false
        }],
        ['irina18', {
          'sharing': 0.7,
          'selfvote_excluded': false
        }],
        ['blainemono', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['jong', {
          'sharing': 0.5,
          'selfvote_excluded': false
        }],
        ['spaceone_pool', {
          'sharing': 0.7,
          'selfvote_excluded': false
        }],
        ['vitalisk', {
          'sharing': 0.8,
          'selfvote_excluded': true
        }],
        ['condor', {
          'sharing': 0.7,
          'selfvote_excluded': false
        }],
        ['sunrise', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['grumlin', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['cc001', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['stellardynamic_vote', {
          'sharing': 0.55,
          'selfvote_excluded': true
        }],
        ['liskpool.top', {
          'sharing': 0.7,
          'selfvote_excluded': false
        }],
        ['ultrafresh', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['shinekami', {
          'sharing': 0.7,
          'selfvote_excluded': true
        }],
        ['pool80percent', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['poolback80', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['zdzik', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['anamix', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['goodwin80', {
          'sharing': 0.8,
          'selfvote_excluded': true
        }],
        ['commulab', {
          'sharing': 0.5,
          'selfvote_excluded': false
        }],
        ['anonimowy891', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['przemer', {
          'sharing': 0.6,
          'selfvote_excluded': true
        }],
        ['friartuck', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['robinhood', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['liberspirita', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['spirita', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['spirita2', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['sherwood', {
          'sharing': 0.8,
          'selfvote_excluded': false
        }],
        ['mrgr', {
          'sharing': 0.3,
          'selfvote_excluded': false
        }],
        ['liskcenter.io', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['qdentica', {
          'sharing': 0.6,
          'selfvote_excluded': true
        }],
        ['corsaro', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['ilgio', {
          'sharing': 0.5,
          'selfvote_excluded': false
        }],
        ['gaxda', {
          'sharing': 0.8,
          'selfvote_excluded': true
        }],
        ['colecti', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['t3ran13', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['gregorst', {
          'sharing': 0.5,
          'selfvote_excluded': true
        }],
        ['savetheworld', {
          'sharing': 0.5,
          'selfvote_excluded': false
        }],
        ['hitman', {
          'sharing': 0.5,
          'selfvote_excluded': false
        }],
        ['devasive', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['mrv', {
          'sharing': 0,
          'selfvote_excluded': true
        }],
        ['dav1', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['lisk_nft', {
          'sharing': 0.07,
          'selfvote_excluded': false
        }],
        ['liskjapan', {
          'sharing': 0.05,
          'selfvote_excluded': false
        }],
        ['hong', {
          'sharing': 0.02,
          'selfvote_excluded': false
        }],
        ['eddedw', {
          'sharing': 0,
          'selfvote_excluded': false
        }],
        ['phinx', {
          'sharing': 0.03,
          'selfvote_excluded': true
        }],
        ['kc', {
          'sharing': 0.03,
          'selfvote_excluded': false
        }],
        ['suepaphly', {
          'sharing': 0.25,
          'selfvote_excluded': false
        }],


      ]),

      pools: new Map(),
      payouts: new Map(),
      delegateAddresses: new Map(),
      delegates: new Map(),
      votedForCc001: false,
      notVoted: false,
      payoutsArray: [],
      votingTransactions: [],
      votesSort: 'apr_real',
      votesSortDesc: true,
      nonVotesSort: 'apr_real',
      nonVotesSortDesc: true,
      poolhonesty: new Map(),
      delegatehonesty: [],
      directLink: '',
      directLinkText: '',
      saveAccountName: '',
      savedAccounts: []
    }),
    watch: {
      votes: {
        handler() {
          this.getNonVotes();
        },
        deep: true
      },
      selectDayRanges: {
        handler: function() {
          this.getPoolHonesty(this.selectDayRanges.range);
        },
        deep: true
      },
      poolhonesty: {
        handler: function() {
          // console.log("from watch poolhonesty")
          this.generateDelegateHonestyArray();
        },
        deep: true
      },
    },
    async mounted() {
      if (localStorage.getItem('savedAccounts')) {
        try {
          this.savedAccounts = JSON.parse(localStorage.getItem('savedAccounts'));
        } catch (e) {
          localStorage.removeItem('savedAccounts');
        }
      }

      if (this.$route.params.address) {
        this.address = this.$route.params.address
      }
      await this.getPoolWeights()
      await this.getLiskPrice()
      await this.generatePoolHonesty()
      this.refreshAddress()
      window.addEventListener('resize', this.onResize)
      this.onResize()
      this.getAllDelegates()

      // this.getPoolHonesty()
    },

    computed: {
      shortenedAddress() {
        let address = this.address;
        return address.slice(0, 7) + "...." + address.slice(-4);
      },
    },

    methods: {
      shortenedString(string, prefix, suffix) {
        return shortenedString(string, prefix, suffix)
      },
      onResize() {
        if (window.innerWidth < 769)
          this.isMobile = true;
        else
          this.isMobile = false;
      },
      loadDetails({
        item
      }) {
        if (item.payouSessions) {
          return
        }
        const username = item.username
        const url = "https://validator.lemii.dev/api/v1/" + username + "?extended=true&limit=-1"
        axios.get(url)
          .then((resp) => {
            let result = resp.data;
            let payoutSessions = result.data[0].payoutSessions;
            for (let payoutSession of payoutSessions) {
              const promisedSharing = this.pool_sharings.get(username) ? this.pool_sharings.get(username).sharing * 100 : 0
              const honesty = payoutSession.calculatedShare / promisedSharing * 100
              const honestycolor = this.getHonestyColor(honesty, promisedSharing)
              let date = new Date(parseInt(payoutSession.startTimestamp) * 1000);
              const datestring = date.toLocaleString()
              payoutSession['honesty'] = honesty
              payoutSession['honestycolor'] = honestycolor
              payoutSession['date'] = datestring
            }
            item.payoutSessions = payoutSessions
          })
      },
      clearAddress() {
        this.address = ""
        this.saveAccountName = ""
      },
      saveAccount() {
        const resolved = this.$router.resolve({
          name: 'Address',
          params: {
            address: this.address
          }
        })

        this.savedAccounts.push({
          name: this.saveAccountName,
          address: this.address,
          link: window.location.origin + resolved.href
        })
        const parsedSavedAccounts = JSON.stringify(this.savedAccounts);
        localStorage.setItem('savedAccounts', parsedSavedAccounts);

      },
      saveAccountNameExists(accountName) {
        let saveAccountNameExists = false
        if (localStorage.getItem('savedAccounts')) {
          try {
            let savedAccounts = JSON.parse(localStorage.getItem('savedAccounts'));
            for (let savedAccount of savedAccounts) {
              if (savedAccount.name == accountName) {
                saveAccountNameExists = true
                break
              }
            }
          } catch (e) {
            localStorage.removeItem('savedAccounts');
          }
        }
        return saveAccountNameExists
      },
      itemRowBackground: function(item) {
        return item.pomed ? 'red-background' : ''
      },
      removeSavedAccount(address) {
        this.savedAccounts = this.savedAccounts.filter(account => account.name != address)
        const parsedSavedAccounts = JSON.stringify(this.savedAccounts);
        localStorage.setItem('savedAccounts', parsedSavedAccounts);
      },
      liskoberserverLink(transactionId) {
        return "https://lisk.observer/transaction/" + transactionId
      },
      liskscanLink(transactionId) {
        return "https://liskscan.com/transaction/" + transactionId
      },
      createPayoutsArray() {
        let array = []
        this.payouts.forEach((value, key) => {
          const delegatelink = "https://liskscan.com/account/" + value[0].senderaddress
          const pomed = this.pools.get(key) ? this.pools.get(key).pomed : false
          let payout = {
            'delegate': key,
            'pomed': pomed,
            'delegatelink': delegatelink,
            'number_payouts': value.length,
            'payouts': value,
            'total_payouts_lsk': value.reduce((a, b) => a + (b['amount_lsk'] || 0), 0),
            'total_payouts_usd': value.reduce((a, b) => a + (b['amount_usd'] || 0), 0),
          }
          array.push(payout)
        })
        this.payoutsArray = array
      },
      sumField(list, key) {
        return list.reduce((a, b) => a + (b[key] || 0), 0)
      },
      // isValidAccount(address) {
      //   return /^lsk[a-z0-9]{38}$/.test(address)
      // },
      isValidAccount(address) {
        return isValidAccount(address)
      },
      async getLiskPrice() {
        let price = 0.0
        let url = "https://min-api.cryptocompare.com/data/price?fsym=lsk&tsyms=usd";
        let resp = await axios.get(url);
        if (resp && resp.data) {
          price = resp.data.USD
        }
        this.lskPrice = price
      },
      getLastPayoutColor(bestValue, worstValue, value) {
        const colors_manual = [
          "#ff0000",
          "#ff2c00",
          "#fc5300",
          "#f47000",
          "#e98a00",
          "#dba100",
          "#c8b600",
          "#b2ca00",
          "#95dd00",
          "#6dee00",
          "#00ff00",
        ]
        let colors = colors_manual
        let reversed = false
        if (bestValue < worstValue) {
          reversed = true
        }
        let colorIndex = 0
        colorIndex = Math.floor((value - bestValue) / ((worstValue - bestValue) / colors.length))
        if (reversed) {
          colors = colors.reverse()
          if (value < bestValue) {
            colorIndex = 0
          }
          if (value > worstValue) {
            colorIndex = colors.length - 1
          }
        }

        const color = colors[colorIndex]
        return color
      },
      getHonestyColor(honesty, promisedSharing = 0) {

        const colors_manual = [
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#ff2c00",
          "#fc5300",
          "#f47000",
          "#e98a00",
          "#dba100",
          "#c8b600",
          "#b2ca00",
          "#95dd00",
          "#6dee00",
          "#00ff00",
          "#aaaaaa",
        ]


        const colors = colors_manual
        const numberColors = colors.length - 1
        let colorIndex = parseInt(honesty / (100 / numberColors))
        if (colorIndex >= numberColors)
          colorIndex = numberColors - 1
        if (promisedSharing == 0) {
          colorIndex = colors.length
        }
        let color = colors[colorIndex]
        return color
      },
      getHonesty(delegate, sharing_percentage) {
        if (sharing_percentage == 0) {
          return 100
        }
        let honesty = this.poolhonesty.get(delegate)
        let calculatedAverageShare = 0
        if (honesty) {
          calculatedAverageShare = honesty.averageShared / sharing_percentage * 100
        }
        return calculatedAverageShare
      },
      getAllDelegates() {
        const limit = 101
        let offset = 0
        const topDelegates = 101
        this.delegates.clear()
        for (; offset + limit <= topDelegates; offset += limit) {
          let url = "https://service.lisk.com/api/v2/accounts?isDelegate=true&limit=" + limit + "&offset=" + offset + "&sort=rank%3Aasc"
          axios.get(url)
            .then((resp) => {
              let delegates = resp.data.data;
              for (let delegate of delegates) {
                const rank = delegate.dpos.delegate.rank
                this.delegates.set(rank, delegate)
              }
              this.generateDelegateHonestyArray()
              this.getNonVotes()
            })
        }
      },
      async getPoolWeights() {
        const urls = [
          "https://service.lisk.com/api/v2/accounts?isDelegate=true&limit=100&offset=0&sort=rank%3Aasc",
          "https://service.lisk.com/api/v2/accounts?isDelegate=true&limit=100&offset=100&sort=rank%3Aasc",
          "https://service.lisk.com/api/v2/accounts?isDelegate=true&limit=100&offset=200&sort=rank%3Aasc",
          "https://service.lisk.com/api/v2/accounts?isDelegate=true&limit=100&offset=300&sort=rank%3Aasc",
          // "https://service.lisk.com/api/v2/accounts?isDelegate=true&limit=100&offset=400&sort=rank%3Aasc",
          // "https://service.lisk.com/api/v2/accounts?isDelegate=true&limit=100&offset=500&sort=rank%3Aasc",
        ]

        this.pools.clear()
        let results = await axios.all(urls.map(url => axios.get(url)))

        for (let result of results) {
          let delegates = result.data.data
          for (let delegate of delegates) {
            const username = delegate.dpos.delegate.username
            const address = delegate.summary.address
            const total_weight = parseInt(delegate.dpos.delegate.totalVotesReceived) / this.SATOSHIS
            const rank = delegate.dpos.delegate.rank
            const pomed = delegate.dpos.delegate.status == "punished"
            let self_vote = 0
            if (delegate.dpos.sentVotes) {
              const foundSelfVote = delegate.dpos.sentVotes.find(vote => vote.delegateAddress === delegate.summary.address)
              self_vote = foundSelfVote ? foundSelfVote.amount / this.SATOSHIS : 0;
            }

            const stats = {
              total_weight: total_weight,
              self_vote: self_vote,
              rank: rank,
              pomed: pomed,
            }
            this.pools.set(username, stats)
            this.delegateAddresses.set(address, username)
          }
        }
      },
      refreshAddress() {
        this.updateDirectLink()
        this.getVotes()
        // this.getNonVotes()
        this.getPayouts()
        this.getVotingTransactions()

      },
      updateDirectLink() {

        if (this.address && isValidAccount(this.address)) {
          let push = false
          if (!this.$route.params.address) {
            push = true
          }
          const resolved = this.$router.resolve({
            name: 'Address',
            params: {
              address: this.address
            }
          })
          this.directLink = resolved.href
          this.directLinkText = window.location.origin + resolved.href
          if (push) {
            this.$router.push({
              name: 'Address',
              params: {
                address: this.address
              }
            })
          }
        } else {
          let push = false
          if (this.directLink != "") {
            push = true
          }
          this.directLink = ""
          this.directLinkText = ""
          if (push) {
            this.$router.push({
              name: 'Home'
            })
          }

        }
      },
      async getPayouts() {
        this.payouts.clear()
        this.payoutsArray = []
        const limit = 100
        let offset = 0
        var count = 0
        let total = 999
        if (this.address && isValidAccount(this.address)) {
          do {
            const url = "https://service.lisk.com/api/v2/transactions?moduleAssetName=token%3Atransfer&recipientAddress=" + this.address + "&limit=" + limit + "&offset=" + offset + "&includePending=false&sort=timestamp%3Adesc"
            let resp = await axios.get(url);
            let receivedtransactions = resp.data.data;
            count = resp.data.meta.count;
            total = resp.data.meta.total;


            for (let transaction of receivedtransactions) {
              const sender = transaction.sender.username
              if (!sender) {
                continue
              }
              const amount_lsk = parseInt(transaction.asset.amount) / this.SATOSHIS
              const amount_usd = amount_lsk * this.lskPrice
              const timestamp = transaction.block.timestamp
              const transactionid = transaction.id
              let date = new Date(parseInt(timestamp) * 1000);
              // const datetimestring = "15.5.5."
              const datetimestring = date.toLocaleString()
              let payout = {
                'senderaddress': transaction.sender.address,
                'timestamp': timestamp,
                'datetimestring': datetimestring,
                'amount_lsk': amount_lsk,
                'amount_usd': amount_usd,
                'transactionid': transactionid,
              }
              let delegate = this.payouts.get(sender) ? this.payouts.get(sender) : []
              delegate.push(payout)
              this.payouts.set(sender, delegate)
            }
            offset = offset + limit
          } while (count + offset - limit < total)
          this.createPayoutsArray()
        }
      },
      // shortenedString(string, prefix, suffix) {
      //   return string.slice(0, prefix) + "..." + string.slice(-suffix)
      // },
      getVotingTransactions() {
        const limit = 100
        const offset = 0
        this.votingTransactions = []
        if (this.address && isValidAccount(this.address)) {
          const url = "https://service.lisk.com/api/v2/transactions?senderAddress=" + this.address + "&moduleAssetName=dpos%3AvoteDelegate&limit=" + limit + "&offset=" + offset + "&includePending=false&sort=timestamp%3Adesc"
          this.votingTransactions = []
          axios.get(url)
            .then((resp) => {
              let result = resp.data.data;
              if (result) {
                for (let votingTransaction of result) {
                  let date = new Date(parseInt(votingTransaction.block.timestamp) * 1000);
                  const datetimestring = date.toLocaleString()
                  this.votingTransactions.push({
                    'id': votingTransaction.id,
                    'id_short': shortenedString(votingTransaction.id, 4, 4),
                    'transactionlink': "https://liskscan.com/transaction/" + votingTransaction.id,
                    'timestamp': votingTransaction.block.timestamp,
                    'datetimestring': datetimestring,
                    'votes': votingTransaction.asset.votes.map(vote => {
                      return {
                        'address': vote.delegateAddress,
                        'amount': vote.amount / this.SATOSHIS,
                        'username': this.delegateAddresses.get(vote.delegateAddress) ? this.delegateAddresses.get(vote.delegateAddress) : shortenedString(vote.delegateAddress, 7, 4)
                      }
                    })
                  })

                }
              }
            })
        }
      },
      async generatePoolHonesty() {
        this.poolhonesty.clear()

        const url = "https://validator.lemii.dev/api/v1?limit=5"
        const resp = await axios.get(url)
        if (resp) {
          let result = resp.data;
          let pools = result.data;

          let updateDate = new Date(result.meta.isoString)
          this.lastUpdate_datestring = updateDate.toLocaleString()
          for (let pool of pools) {
            let username = pool.username
            let poolObject = {
              promisedShare: pool.promisedShare,
              averageShared: pool.averageShared,
              lastPayout: pool.daysSinceLastPayout,
              totalPayoutSessions: pool.totalPayoutSessions,
            }
            this.poolhonesty.set(username, poolObject)
          }
          this.generateDelegateHonestyArray();
        }
      },
      generateDelegateHonestyArray() {
        this.delegatehonesty = []

        for (let [rank, delegate] of this.delegates) {
          const username = delegate.dpos.delegate.username
          const sharing_percentage = this.pool_sharings.get(username) ? this.pool_sharings.get(username).sharing * 100 : 0
          const honesty = this.getHonesty(username, sharing_percentage)
          const honestycolor = this.getHonestyColor(honesty, sharing_percentage)
          const shared = this.poolhonesty.get(username) && this.poolhonesty.get(username).averageShared ? this.poolhonesty.get(username).averageShared.toFixed(0) : 0
          const lastPayout = (this.poolhonesty.get(username) && this.poolhonesty.get(username).lastPayout) ? this.poolhonesty.get(username).lastPayout.toFixed(1) : -1
          const lastPayoutColor = this.getLastPayoutColor(0, 14, lastPayout)
          const payoutSessions = this.poolhonesty.get(username) ? this.poolhonesty.get(username).payoutSessions : []
          const numberPayouts = this.poolhonesty.get(username) ? this.poolhonesty.get(username).totalPayoutSessions : 0
          this.delegatehonesty.push({
            'username': username,
            'rank': rank,
            'honesty': honesty,
            'honestycolor': honestycolor,
            'promised': sharing_percentage,
            'shared': shared,
            'lastPayout': lastPayout,
            'lastPayoutColor': lastPayoutColor,
            'recordedSessions': numberPayouts,
            'payoutSessions': payoutSessions,
          })
        }
      },
      getVotes() {
        this.votes = []
        if (this.address && isValidAccount(this.address)) {
          this.votedForCc001 = false
          this.notVoted = false
          const url = "https://service.lisk.com/api/v2/votes_sent?address=" + this.address;
          axios.get(url)
            .then((resp) => {
              let result = resp.data.data;
              if (!result.votes) {
                this.notVoted = true
              } else {
                for (let vote of result.votes) {
                  const delegateUsername = vote.username

                  if (delegateUsername == 'cc001') {
                    this.votedForCc001 = true
                  }
                  const weight = parseInt(vote.amount) / this.SATOSHIS
                  const delegatelink = "https://liskscan.com/account/" + vote.address
                  const sharing_percentage = this.pool_sharings.get(vote.username) ? this.pool_sharings.get(vote.username).sharing * 100 : 0
                  const delegate = this.pools.get(delegateUsername)
                  const pomed = delegate.pomed
                  const total_pool_weight = delegate ? delegate.total_weight : 0;
                  const selfvote_excluded = this.pool_sharings.get(vote.username) ? this.pool_sharings.get(vote.username).selfvote_excluded : false;
                  const selfvote = delegate ? delegate.self_vote : 0;
                  const counted_pool_weight = selfvote_excluded ? total_pool_weight - selfvote : total_pool_weight
                  const pool_rank = delegate ? delegate.rank : -1
                  const reward_block = pool_rank <= 101 && pool_rank > 0 && !pomed ? weight / counted_pool_weight * sharing_percentage / 100.0 : 0
                  const reward_month_lsk = reward_block * this.BLOCKS_PER_MONTH
                  const reward_month_usd = reward_month_lsk * this.lskPrice
                  const apr = reward_month_lsk / weight * 12 * 100

                  const honesty = this.getHonesty(delegateUsername, sharing_percentage)
                  const honestycolor = this.getHonestyColor(honesty, sharing_percentage)
                  const reward_month_lsk_real = reward_month_lsk * honesty / 100
                  const apr_real = reward_month_lsk_real / weight * 12 * 100

                  this.votes.push({
                    delegate: delegateUsername,
                    pomed: pomed,
                    delegatelink: delegatelink,
                    weight: weight,
                    sharingpercentage: sharing_percentage,
                    total_pool_weight: total_pool_weight,
                    counted_pool_weight: counted_pool_weight,
                    selfvote_excluded: selfvote_excluded,
                    selfvote: selfvote,
                    pool_rank: pool_rank,
                    reward_block: reward_block,
                    reward_month_lsk: reward_month_lsk,
                    reward_month_usd: reward_month_usd,
                    reward_month_lsk_real: reward_month_lsk_real,
                    apr: apr,
                    apr_real: apr_real,
                    honesty: honesty,
                    honestycolor: honestycolor,
                  })
                }
              }
            })
        }
      },
      getNonVotes() {
        this.nonVotes = []
        if (parseFloat(this.voteWeight) > 0 && (!this.address || (this.address && isValidAccount(this.address)))) {
          for (let delegate of this.delegates.values()) {
            if (!this.votes.find(vote => vote.delegate === delegate.dpos.delegate.username)) {
              const username = delegate.dpos.delegate.username
              const weight = parseFloat(this.voteWeight)
              const delegatelink = "https://liskscan.com/account/" + delegate.summary.address
              const sharing_percentage = this.pool_sharings.get(delegate.dpos.delegate.username) ? this.pool_sharings.get(delegate.dpos.delegate.username).sharing * 100 : 0
              const pool = this.pools.get(username)
              const total_pool_weight = pool ? pool.total_weight : 0;
              const pomed = pool ? pool.pomed : false
              const selfvote_excluded = this.pool_sharings.get(delegate.dpos.delegate.username) ? this.pool_sharings.get(delegate.dpos.delegate.username).selfvote_excluded : false;
              const selfvote = pool ? pool.self_vote : 0;
              const counted_pool_weight = selfvote_excluded ? total_pool_weight - selfvote : total_pool_weight
              const pool_rank = pool ? pool.rank : -1
              const reward_block = pool_rank <= 101 && pool_rank > 0 && !pomed ? weight / (counted_pool_weight + weight) * sharing_percentage / 100.0 : 0
              const reward_month_lsk = reward_block * this.BLOCKS_PER_MONTH
              const reward_month_usd = reward_month_lsk * this.lskPrice
              const apr = reward_month_lsk / weight * 12 * 100
              const honesty = this.getHonesty(username, sharing_percentage)
              const honestycolor = this.getHonestyColor(honesty, sharing_percentage)
              const reward_month_lsk_real = reward_month_lsk * honesty / 100
              const apr_real = reward_month_lsk_real / weight * 12 * 100
              this.nonVotes.push({
                delegate: username,
                pomed: pomed,
                delegatelink: delegatelink,
                weight: weight,
                sharingpercentage: sharing_percentage,
                total_pool_weight: total_pool_weight,
                counted_pool_weight: counted_pool_weight,
                selfvote_excluded: selfvote_excluded,
                selfvote: selfvote,
                pool_rank: pool_rank,
                reward_block: reward_block,
                reward_month_lsk: reward_month_lsk,
                reward_month_usd: reward_month_usd,
                apr: apr,
                apr_real: apr_real,
                honesty: honesty,
                honestycolor: honestycolor,
              })
            }

          }
        }
      }
    }
  }
</script>
<style>
td.payout {
    height: 25px !important;
  }

  .mobile {
    color: 'red';
  }

  @media screen and (max-width: 768px) {
    .mobile table.v-table tr {
      max-width: 100%;
      position: relative;
      display: block;
    }

    .mobile table.v-table tr:nth-child(odd) {
      border-left: 6px solid deeppink;
    }

    .mobile table.v-table tr:nth-child(even) {
      border-left: 6px solid cyan;
    }

    .mobile table.v-table tr td {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #f5f5f5;
      height: auto;
      padding: 10px;
    }

    .mobile table tbody tr td ul li:before {
      content: attr(data-label);
      padding-right: .5em;
      text-align: left;
      float: left;
      color: #000000;

    }

    .mobile table tbody tr td ul li {
      content: attr(data-label);
      padding-right: .5em;
      text-align: right;
      display: block;
      color: #999999;

    }

    .v-datatable__actions__select {
      width: 50%;
      margin: 0px;
      justify-content: flex-start;
    }

    .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
      background: transparent;
    }

  }

  .flex-content {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .chipsize {
    width: 80px;
    justify-content: center;
  }

  .width100 {
    width: 100px;
  }

  .width150 {
    width: 150px;
  }

  .width300 {
    width: 300px;
  }

  .chipsize2 {
    width: 55px;
    justify-content: center;
  }

  .flex-item {
    padding: 1px;
    width: 100%;
    height: 25px;
    font-weight: bold;
  }

  .red-background {
    background-color: rgb(245, 183, 177)
  }
</style>
